define([
    "angular",
    "lodash",
    "../../arrays/arrays",
    "./../nlgLGPDModule",
    "json!../../tdnHelp/tdnSpecification.json"
], function (angular,_, arrays, lgpdModule, tdnSpecification) {
    "use strict";
    return lgpdModule.controller("nlgLGPDObfuscationController", [
        "$scope",
        "autocompleteFactory",
        "messagesModal",
        "remoteExceptionHandler",
        "$state",
        "$q",
        "loadingService",
        "nlgLGPDService",
        "$translate",
        "xlsService",
        "tdnHelp",
        "spreadsheetExportService",
        function ($scope, autocompleteFactory, messagesModal, remoteExceptionHandler, $state, $q, loadingService, nlgLGPDService, $translate, xlsService, tdnHelp, spreadsheetExportService) {
            var paginationApi;
            $scope.entityNames = getAllDefinitionEntityNames();

            if (tdnSpecification && tdnSpecification[tdnHelp.getContext()] && tdnSpecification[tdnHelp.getContext()]["obfuscation"]) {
                $scope.tdn = tdnSpecification[tdnHelp.getContext()]["obfuscation"];
            }
            $scope.context = tdnHelp.getContext();

            $scope.model = {
                entityDefinition: null,
                entityField: null
            };
            $scope.profileList = [];

            $scope.getAvailableProfiles = function () {
                return $scope.profileList;
            };

            $scope.getEntityName = function (tag) {
                if (!tag) return "";
                return "(" + _.capitalize($translate.instant(tag.artifactName)) + "/" +
                    _.capitalize(tag.translatedServiceName) + ") - " + tag.presentationName;
            };

            $scope.getEntityFieldName = function (tag) {
                if (!tag) return "";
                if (!tag.presentationField) return tag.entityField;
                return tag.presentationField;
            };

            function getAllDefinitionEntityNames() {
                $scope.serviceName = nlgLGPDService.availableServiceNames();
                return loadingService($q.all(nlgLGPDService.getAllObfuscationDefinitionEntityNames()))
                    .then(function (allItems) {
                        var resultNames = [];
                        allItems.forEach(function (entry) {
                            entry.value.forEach(function (name) {
                                var nameEntry = {
                                    entityName: name.entityName,
                                    presentationName: name.presentationName,
                                    artifactName: entry.key.artifactName,
                                    serviceName: name.type.description,
                                    translatedServiceName: $translate.instant(name.type.description)
                                };
                                resultNames.push(nameEntry);
                            });
                        });
                        return resultNames;
                    });
            }

            $scope.getEntitiesByName = function (definiton) {
                var template = {
                    entityName: definiton.entityName,
                    type: {"description": definiton.serviceName},
                    artifactName: definiton.artifactName
                };
                return nlgLGPDService.getObfuscationEntitiesByServicePathAndTemplate(template);
            };

            $scope.findEntities = function () {
                return paginationApi.seekPage(1);
            };


            $scope.save = function (entity) {
                return nlgLGPDService.saveObfuscationProfile(prepareData(entity))
                    .then(function () {
                        messagesModal("dialog.success", {
                            keyBundle: "lgpd.edition.save.success",
                        });
                    }).catch(remoteExceptionHandler());
            };
            $scope.checkEntity = function () {
                if (!$scope.model.entityDefinition) {
                    $scope.model.entityField = null;
                }
            };


            $scope.exportSpreedSheet = function () {
                var dataToExport = [];
                var tableRecords = angular.copy($scope.tableOptions.records);
                tableRecords.forEach(function (record) {
                    dataToExport.push(createRow(record));
                });

                var spreadsheetName = $translate.instant("lgpd.obfuscation.spreadsheet");
                return spreadsheetExportService.exportSpreadsheet(spreadsheetName, spreadsheetName, createHeaders(), dataToExport);
            };

            function createHeaders() {
                return [
                    $translate.instant("lgpd.obfuscation.entity"),
                    $translate.instant("lgpd.obfuscation.data"),
                    $translate.instant("justification"),
                    $translate.instant("lgpd.obfuscation.type"),
                    $translate.instant("lgpd.obfuscation.classification"),
                    $translate.instant("lgpd.obfuscation.profiles")
                ];
            }

            function createRow(record){
                return [
                    record.presentationName,
                    record.presentationField || record.entityField,
                    record.justification,
                    record.translatedType,
                    getClassification(record),
                    extractProfilePermissions(record.profilePermissionIds)
                ];
            }

            $scope.tableOptions = {
                records: [],
                onRegisterPagination: function (api) {
                    paginationApi = api;
                },
                pagination: {
                    onPaginate: function (firstResult, maxResults) {
                        var entityField = null;
                        if ($scope.model.entityField && $scope.model.entityField.entityField) {
                            entityField = $scope.model.entityField.entityField;
                        }
                        var template = {
                            entityName: $scope.model.entityDefinition.entityName,
                            entityField: entityField,
                            type: {"description": $scope.model.entityDefinition.serviceName}
                        };
                        var data = {
                            findBy: template,
                            serviceName: $scope.model.entityDefinition.serviceName,
                            artifactName: $scope.model.entityDefinition.artifactName
                        };

                        return loadingService(nlgLGPDService.getObfuscationEntities(data, firstResult, maxResults)
                            .then(function (response) {
                                $scope.profileList = response.data.availableProfiles;
                                var definitions = [];
                                arrays.each(response.data.definitionPayload, function (payload) {
                                    payload.definition.classification = getClassification(payload.definition);
                                    payload.definition.profilePermissionIds = getProfileByIds(payload);
                                    payload.definition.translatedType = $translate.instant(payload.definition.type.description);
                                    definitions.push(payload.definition);
                                });
                                return {
                                    result: definitions,
                                    count: response.data.count,
                                    firstResult: response.data.firstResult,
                                    maxResults: maxResults
                                };
                            }).catch(remoteExceptionHandler()));
                    }
                }
            };

            function extractProfilePermissions(profilePermissions) {
                if (!profilePermissions || !profilePermissions.length) {
                    return;
                }
                var description = [];
                profilePermissions.forEach(function (profile) {
                    description.push(profile.sourceId);
                });
                return description.join(" / ");
            }

            function prepareData(entity) {
                return {
                    artifactName: $scope.model.entityDefinition.artifactName,
                    data: {
                        definition: entity,
                        profilePermissionIds: extractprofilePermissionsId(entity.profilePermissionIds)
                    }
                };
            }

            function extractprofilePermissionsId(profilePermissionss) {
                if (!profilePermissionss) {
                    return [];
                }
                var ids = [];
                profilePermissionss.forEach(function (profile) {
                    ids.push(profile.id);
                });
                return ids;
            }

            function getProfileByIds(payload) {
                if (!payload.profilePermissionIds) {
                    return;
                }
                var profiles = [];
                for (var i = 0; i < payload.profilePermissionIds.length; i++) {
                    arrays.addAll(profiles, arrays.filter($scope.profileList, function (profile) {
                        return profile.id === payload.profilePermissionIds[i];
                    }));
                }
                return profiles;
            }

            function getClassification(definition) {
                var classifications = [];

                if (definition.personal) {
                    classifications.push($translate.instant("lgpd.personal"));
                }
                if (definition.sensitive) {
                    classifications.push($translate.instant("lgpd.sensitive"));
                }
                if (definition.anonymizable) {
                    classifications.push($translate.instant("lgpd.anonymizable"));
                }
                return classifications.join("/ ");
            }
        }]);
});
