define([
    "angular",
    "../remote/remote",
    "angular-ui-bootstrap",
], function (angular) {
    "use strict";

    return angular.module("remoteServerModule", [
        "remoteModule",
        "ui.bootstrap"
    ]);
});