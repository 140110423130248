define([
    "./remoteServerModule",
], function (remoteServerModule) {
    "use strict";

    return remoteServerModule.provider("remoteServerUrls", [function () {
        var _urls = {
            "config-params.get-remote-config-params": "",
            "config-params.update-remote-config-params": "",
            "config-params.test-routing-connection": "",
        };

        this.setUrls = function (urls) {
            _urls = urls;
        };

        this.$get = [function () {
            return _urls;
        }];
    }]);
});