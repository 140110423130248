define(["./microstrategyModule", "json!../tdnHelp/tdnSpecification.json"], function (microstrategyModule, tdnSpecification) {
    "use strict";

    return microstrategyModule.controller("microstrategyController", [ "$scope", "tdnHelp",
        function ($scope, tdnHelp) {
            $scope.tdn = undefined;
            if (tdnSpecification && tdnSpecification[tdnHelp.getContext()] && tdnSpecification[tdnHelp.getContext()]["bi"]) {
                $scope.tdn = tdnSpecification[tdnHelp.getContext()]["bi"];
            }
            $scope.context = tdnHelp.getContext();
        }]);
});

