define(["./remoteServerModule"], function (remoteServerModule) {

    RemoteServerService.$inject = ["$http", "remoteServerUrls"];

    function RemoteServerService($http, remoteServerUrls) {
        this.$http = $http;
        this.remoteServerUrls = remoteServerUrls;
    }

    RemoteServerService.prototype.getRemoteServicesConfigParams = function () {
        return this.$http.get(this.remoteServerUrls["config-params.get-remote-config-params"])
            .then(getData);
    };

    RemoteServerService.prototype.updateRemoteServicesConfigParams = function (remoteServicesConfigParams) {
        return this.$http.post(this.remoteServerUrls["config-params.update-remote-config-params"], remoteServicesConfigParams)
            .then(getData);
    };

    RemoteServerService.prototype.testRoutingConnection = function (remoteServicesSet) {
        return this.$http.post(this.remoteServerUrls["config-params.test-routing-connection"], remoteServicesSet)
            .then(getData);
    };

    function getData(response) {
        return response.data;
    }

    return remoteServerModule.service("remoteServerService", RemoteServerService);
});