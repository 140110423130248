define(["./microstrategyModule",], function (module) {
    return module.directive("nlgMicroStrategy", [function () {
        "use strict";

        return {
            restrict: "E",
            template: "<div id=\"LibraryHomePageContainer\" style=\"width: 100%; min-height: 85vh\"></div>",
            scope: {},
            controller: ["loadingService", "$http", "remoteExceptionHandler", "microstrategyServiceUrl", function (loadingService, $http, remoteExceptionHandler, microstrategyServiceUrl) {

                var BASE_URL = "https://totvs.cloud.microstrategy.com/MicroStrategyLibrary/";
                var LIBRARY_URL = BASE_URL + "app/config/" + microstrategyServiceUrl.microstrategyLibraryIdentifier + "?app.embedded=true";

                var microstrategy = window.microstrategy;
                var config = {
                    serverUrl: LIBRARY_URL, placeholder: document.getElementById("LibraryHomePageContainer")
                };
                return loadingService(loginInternal()).then(function () {
                    return microstrategy.embeddingContexts.embedLibraryPage(config);
                });

                function loginInternal() {
                    return $http.get(microstrategyServiceUrl.loginUrl)
                        .catch(remoteExceptionHandler())
                        .then(function (response) {
                            return authenticate(response.data.token);
                        });
                }

                function authenticate(token) {
                    var options = {
                        method: "POST",
                        credentials: "include",
                        mode: "cors",
                        headers: {"content-type": "application/json"},
                        body: JSON.stringify({
                            "loginMode": -1, "identityToken": token
                        })
                    };

                    var DELEGATE_SESSION_URL = BASE_URL + "api/auth/delegate";
                    return fetch(DELEGATE_SESSION_URL, options);
                }
            }]
        };
    }]);

});