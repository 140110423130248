define(["angular", "./nlgNumberInputModule", "text!./nlgNumberInput.html", "lodash"], function (angular, nlgNumberInputModule, template, _) {
    "use strict";

    var keyCode = {
        ARROW_UP: 38,
        ARROW_DOWN: 40
    };

    /**
     * @ngdoc directive
     * @name directives.directive:nlgNumberInput
     * @description
     * Componente de campo numérico responsável por aplicar máscaras de formatação para inteiros e decimais.
     *
     * @param {Object[]=} ngModel Requerido para fazer o bind com o controller do campo associado
     * @param {number=} nlgDecimals Número de casas decimais que a máscara deve contemplar. Deve-se usar 0 para inteiros. *Padrão:* 2.
     * @param {boolean=} nlgNegative Flag que indica se o campo aceita um valor negativo. *Parâmetro opcional.*
     * @param {number=} zerosPadding Quantidade de zeros a esquerda.
     * @param {number=} step Define o quanto o valor do input será incrementado ou decrementado.
     * @param {number=} min Valor mínimo aceito pelo campo associado.
     *      Será definido ao campo perder o foco contendo um valor inferior ao mínimo, ou ao ser omitido. *Padrão:* 0.
     * @param {number=} max Valor máximo aceito pelo campo associado.
     *      Será definido ao campo perder o foco contendo um valor superior ao máximo. *Parâmetro opcional.*
     * @param {boolean=} nullable Flag que indica se o campo pode ter o valor omitido. *Parâmetro opcional.*
     *
     * @example
     * <example module="FrontEndWeb">
     *     <file name="index.html">
     *         <div ng-controller="example">
     *            Valor inteiro: <nlg-number-input ng-model='integerValue' nlg-decimals='0' max='5'></nlg-number-input>
     *            Valor decimal opcional: <nlg-number-input ng-model='nullableValue' nlg-decimals='2' min='1' nullable></nlg-number-input>
     *            Valor decimal obrigatório: <nlg-number-input ng-model='notNullableValue' nlg-decimals='2' min='1'></nlg-number-input>
     *            Valor inteiro negativo: <nlg-number-input ng-model='negativeIntegerValue' nlg-decimals='0' nlg-negative></nlg-number-input>
     *            Valor decimal obrigatório negativo: <nlg-number-input ng-model='negativeDecimalValue' nlg-decimals='2' min='-5' nlg-negative></nlg-number-input>
     *         </div>
     *     </file>
     *     <file name="index.js">
     *         angular.module("FrontEndWeb").controller("example", function ($scope) {
     *              $scope.integerValue = null;
     *              $scope.nullableValue = null;
     *              $scope.notNullableValue = null;
     *              $scope.negativeIntegerValue = null;
     *              $scope.negativeDecimalValue = null;
     *         });
     *     </file>
     * </example>
     */
    return nlgNumberInputModule.directive("nlgNumberInput", ["$locale", function ($locale) {
        return {
            restrict: "E",
            template: template,
            scope: {
                max: "@?",
                min: "@?",
                model: "=ngModel",
                nlgDecimals: "@?",
                nlgStep: "@?",
                nlgNegative: "@?",
                zerosPadding: "@?",
                ngDisabled: "<?",
                cyName: "@?"
            },
            require: "ngModel",
            link: function (scope, element, attrs, controller) {
                scope.cyName = scope.cyName || "nlgNumberInput";
                var input = element.find("input");
                var decimalDelimiter = $locale.NUMBER_FORMATS.DECIMAL_SEP;
                var thousandsDelimiter = $locale.NUMBER_FORMATS.GROUP_SEP;

                if (!negative() && getMin() < 0) {
                    throw new Error("Min value cannot be negative because this input does not allow negative numbers: Min: " + scope.min);
                }

                if (angular.isDefined(scope.min) &&
                    angular.isDefined(scope.max) &&
                    getBinding(scope.min) > getBinding(scope.max)) {
                    throw new Error(["Max value should be greater than min value. Min: ", scope.min, "; Max: ", scope.max, "."].join(""));
                }

                controller.$formatters.push(function (modelValue) {
                    scope.model = clamp(modelValue);
                    return scope.model;
                });
                controller.$formatters.unshift(toString);
                controller.$render = function () {
                    input.val(leftpad(controller.$viewValue));

                    function leftpad(string) {
                        if (!string) {
                            return "";
                        }
                        var length = getBinding(scope.zerosPadding, 0);
                        if (length === 0) {
                            return string;
                        }
                        var regExp = new RegExp("^(\\-?)(\\d+)(\\" + decimalDelimiter + "\\d+)?$");
                        var signAndNumberAndDecimals = string.match(regExp);
                        var sign = signAndNumberAndDecimals[1];
                        var number = signAndNumberAndDecimals[2];
                        var decimal = signAndNumberAndDecimals[3] || "";
                        return sign + _.padStart(number, length - sign.length, "0") + decimal;
                    }
                };
                scope.change = function () {
                    controller.$setViewValue(toString(toNumber(input.val())));
                    controller.$render();
                };
                controller.$parsers.push(toNumber);

                input.on("keydown", function (ev) {
                    var inc = ev.which === keyCode.ARROW_UP ? 1 : ev.which === keyCode.ARROW_DOWN ? -1 : 0;
                    increment(inc, ev, this);
                }).on("mousewheel wheel", function (ev) {
                    if (ev.originalEvent) {
                        ev = ev.originalEvent;
                    }

                    var delta = (ev.wheelDelta) ? ev.wheelDelta : -ev.deltaY;
                    var scrollUp = (ev.detail || delta > 0);
                    increment(scrollUp ? 1 : -1, ev, this);
                });

                function increment(inc, ev) {
                    if(scope.ngDisabled){
                        return;
                    }
                    if (inc === 0) {
                        return;
                    }
                    var step = getBinding(scope.nlgStep, 0);
                    if (step === 0) {
                        return;
                    }
                    ev.preventDefault();
                    var newModel = inc * step + (toNumber(input.val()) || 0);
                    var min = getMin();
                    if (min !== null && newModel < min) {
                        newModel = min;
                    }
                    controller.$setViewValue(toString(clamp(newModel)));
                    controller.$render();
                    scope.$digest();
                }

                scope.$watch(function () {
                    return $locale.NUMBER_FORMATS;
                }, function (numberFormat) {
                    decimalDelimiter = numberFormat.DECIMAL_SEP;
                    thousandsDelimiter = numberFormat.GROUP_SEP;
                    input.attr("pattern", "[\\d\\-" + decimalDelimiter + "]+");
                    controller.$setViewValue(toString(scope.model));
                    controller.$render();
                }, true);

                scope.$watch(function () {
                    return attrs.placeholder;
                }, function (placeholder) {
                    input.attr("placeholder", placeholder);
                });

                function toString(number) {
                    number = clamp(number);
                    if (number === null) {
                        return null;
                    }
                    return number.toFixed(decimals())
                        .replace(",", "")
                        .replace(".", decimalDelimiter);
                }

                function toNumber(string) {
                    if (!string && nullable()) {
                        return null;
                    }
                    var number = (string || "0")
                        .replace(thousandsDelimiter, "")
                        .replace(decimalDelimiter, decimals() === 0 ? "" : ".")
                        .replace(/[^\d.-]/g, "");
                    var signAndNumber = number.match(/^(\-?)(.*)$/);
                    return clamp(parseFloat(signAndNumber[1] + signAndNumber[2].replace("-", "")));
                }

                function clamp(number) {
                    if (invalidNumber(number)) {
                        if (nullable()) {
                            return null;
                        }
                        number = 0;
                    }
                    if (!negative()) {
                        number = Math.abs(number);
                    }
                    var min = getMin();
                    if (min !== null && number < min) {
                        return min;
                    }
                    var max = getBinding(scope.max, null);
                    if (max !== null && number > max) {
                        return max;
                    }
                    return number;
                }

                function invalidNumber(number) {
                    return !angular.isNumber(number) || _.isNaN(number);
                }

                function getMin() {
                    return getBinding(scope.min, negative() ? null : 0);
                }

                function decimals() {
                    return getBinding(scope.nlgDecimals, 2);
                }

                function getBinding(binding, def) {
                    var result = parseFloat(binding);
                    return !_.isNaN(result) ? result : def;
                }

                function nullable() {
                    return checkAttribute("nullable");
                }

                function negative() {
                    return checkAttribute("nlg-negative");
                }

                function checkAttribute(attrName) {
                    var value = element.attr(attrName);
                    return angular.isDefined(value) && value !== "false";
                }
            }
        };
    }]);
});
