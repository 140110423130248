define(["./nlgSelectionModule",
    "text!./nlgSelectionView.html",
    "json!../tdnHelp/tdnSpecification.json",
], function (nlgSelectionModule, template, tdnSpecification) {
    "use strict";

    nlgSelectionModule.directive("nlgSelectionView", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {
                extraActions: "=?"
            },
            controller: [
                "$scope",
                "$http",
                "$modal",
                "messagesModal",
                "nodeIdentifiers",
                "nodeCommandMoveTripsToUser",
                "nodeCommandMoveDeliveryUnitToAnotherUser",
                "loadingService",
                "remoteExceptionHandler",
                "nlgSelectionService",
                "tdnHelp",
                function ($scope,
                          $http,
                          $modal,
                          messagesModal,
                          nodeIdentifiers,
                          nodeCommandMoveTripsToUser,
                          nodeCommandMoveDeliveryUnitToAnotherUser,
                          loadingService,
                          remoteExceptionHandler,
                          nlgSelectionService,
                          tdnHelp) {

                    $scope.tdn = undefined;
                    if (tdnSpecification && tdnSpecification[tdnHelp.getContext()] && tdnSpecification[tdnHelp.getContext()]["selection"]) {
                        $scope.tdn = tdnSpecification[tdnHelp.getContext()]["selection"];
                    }

                    var baskets = {
                        orders: null,
                        trips: null
                    };

                    $scope.selection = {
                        deliveryUnits: {},
                        tripBasket: {}
                    };

                    $scope.registerBasketApi = function (basketApiName, api) {
                        baskets[basketApiName] = api;
                    };

                    $scope.moveTripsToUser = function () {
                        nodeCommandMoveTripsToUser(getSelectedTrips(), {
                            refilter: baskets.trips.refreshFilter
                        });
                    };

                    $scope.hasSelectedTrips = function () {
                        return !!getSelectedTrips().length;
                    };

                    $scope.moveDeliveryUnitsToUser = function () {
                        nodeCommandMoveDeliveryUnitToAnotherUser(getSelectedDeliveryUnits()).then(baskets.orders.refreshFilter);
                    };

                    $scope.canMoveAllDeliveryUnits = function () {
                        if (!baskets.orders) {
                            return false;
                        }
                        var orders = baskets.orders.getData().children;
                        return orders && orders.length;
                    };

                    $scope.moveAllDeliveryUnitsPagesToUser = function () {
                        var patches = baskets.orders.getLastAppliedFilterPatches();
                        nlgSelectionService.getOrderNodeIdentifiers(patches)
                            .then(function (response) {
                                if (response.length) {
                                    nodeCommandMoveDeliveryUnitToAnotherUser(response).then(baskets.orders.refreshFilter);
                                } else {
                                    messagesModal("dialog.warning", [{
                                        keyBundle: "no.order.in.basket",
                                        parameters: []
                                    }]);
                                }
                            });
                    };

                    $scope.hasSelectedDeliveryUnitsFromUserBasket = function () {
                        return !!getSelectedDeliveryUnits().length;
                    };

                    var getSelectedTrips = function () {
                        return nodeIdentifiers.toNodeIdentifiers($scope.selection.tripBasket.Trip || []);
                    };

                    var getSelectedDeliveryUnits = function () {
                        return nodeIdentifiers.toNodeIdentifiers($scope.selection.deliveryUnits.DeliveryUnit || []);
                    };

                    $scope.executeExtraAction = function (action) {
                        action.execute(baskets.orders, getSelectedDeliveryUnits());
                    };
                }]
        };
    }]);
});

