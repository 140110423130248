define(["./remoteServerModule", "text!./remoteServerView.html"], function (remoteServerModule, template) {
    "use strict";

    RemoteServerModalService.$inject = ["$modal"];

    function RemoteServerModalService($modal) {
        this.$modal = $modal;
    }

    RemoteServerModalService.prototype.open = function (remoteConfigParams) {
        return this.$modal.open({
            size: "xl",
            template: template,
            resolve: {
                remoteConfigParam: [
                    "remoteServerService",
                    "loadingService",
                    "remoteExceptionHandler",
                    function (remoteServerService,
                              loadingService,
                              remoteExceptionHandler) {
                        if (remoteConfigParams)
                            return remoteConfigParams;
                        return loadingService(remoteServerService.getRemoteServicesConfigParams())
                            .catch(remoteExceptionHandler());
                    }]
            },
            controllerAs: "ctrl",
            controller: "remoteServerModalController",
        }).result;
    };

    return remoteServerModule.service("remoteServerModalService", RemoteServerModalService);
});