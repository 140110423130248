define(["./nlgTruckAllocationModule"], function (nlgTruckAllocationModule) {
    "use strict";

    return nlgTruckAllocationModule.service("nlgTruckAllocationService", [
        "$http",
        "remoteExceptionHandler",
        "loadingService",
        "filterServiceFactory",
        "nlgTruckAllocation",
        function ($http, remoteExceptionHandler, loadingService, filterServiceFactory, nlgTruckAllocation) {
            var findPath = nlgTruckAllocation.createFind();
            var servicePath = nlgTruckAllocation.createService();
            return {
                saveAllocations: function (truckAllocation) {
                    return $http.post(servicePath, truckAllocation);
                },
                removeAllocation: function (truckAllocation) {
                    return $http.delete(servicePath + truckAllocation.id);
                },
                getAllocations: function(firstResult, maxResults, filterPatch){
                    return loadingService($http.post(findPath, filterPatch, {params:{
                        firstResult: firstResult,
                        maxResults: maxResults
                    }}));
                }
            };
        }]);
});