define([
    "./microstrategyModule"
], function (microstrategy) {
    "use strict";
    microstrategy.provider("microstrategyServiceUrl", [function () {

        var microstrategyLibraryIdentifier;
        var loginUrl;
        var configurationUrl;
        var isAllowedUrl;
        this.setUrl = function (url) {
            microstrategyLibraryIdentifier = url.microstrategyLibraryIdentifier;
            loginUrl = url.loginUrl;
            configurationUrl = url.configurationUrl;
            isAllowedUrl = url.isAllowedUrl;
        };

        this.$get = [function () {
            return {
                microstrategyLibraryIdentifier: microstrategyLibraryIdentifier,
                loginUrl: loginUrl,
                configurationUrl: configurationUrl,
                isAllowedUrl: isAllowedUrl
            };
        }];
    }]);
});

