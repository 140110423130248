define([
    "angular",
    "../arrays/arrays",
    "lodash",
    "./mapsModule"
], function (angular, arrays, _, mapsModule) {
    "use strict";

    return mapsModule.service("nlgRouteStopMarkerService", [function () {
        return {
            addSelectedStyleToCurrentIcon: addSelectedStyleToCurrentIcon,
            drawLocalityIcon: drawLocalityIcon,
            getDefaultStopIcon: getStopIcon.bind(null, "black"),
            getHighlightedStopIcon: getStopIcon.bind(null, "#f4d942")
        };

        function addSelectedStyleToCurrentIcon(stopModel, route, strokeStyle) {
            var canvas = document.createElement("canvas");
            var text = stopModel.stop.sequenceOnTrip + 1;
            canvas.width = 25;
            canvas.height = 25;
            var ctx = canvas.getContext("2d");
            var img = new Image();
            img.src = stopModel.icon;
            img.onload = function () {

                ctx.clearRect(0, 0, canvas.width, canvas.height);

                ctx.fillStyle = route.color;
                ctx.fillRect(0, 0, canvas.width, canvas.height);

                ctx.strokeStyle = "black";
                ctx.strokeRect(0, 0, canvas.width, canvas.height);

                ctx.font = "14px arial";
                ctx.fillStyle = "#fff";
                ctx.strokeStyle = strokeStyle ? strokeStyle : "#f4d942";
                ctx.lineWidth = 2;
                ctx.textAlign = "center";
                ctx.strokeText(text, canvas.width / 2, canvas.height / 2 + 5);
                ctx.fillText(text, canvas.width / 2, canvas.height / 2 + 5);

                stopModel.icon = canvas.toDataURL();
            };
        }

        function drawLocalityIcon(text, imagePath, defer) {
            if (!imagePath){
                return defer.promise;
            }
            var canvas = createCanvas(40, 25);
            var ctx = canvas.getContext("2d");
            var stopNumberXPosition = 27;
            var stopNumberYPosition = canvas.height / 2 + 9;
            drawLinesAndText(ctx, stopNumberXPosition - 2, canvas.height, text, "10px arial", "black", 2, "left", stopNumberXPosition, stopNumberYPosition);

            var background = new Image();
            background.onload = function () {
                ctx.drawImage(background, 0, 0);
                defer.resolve(canvas.toDataURL());
            };
            background.src = imagePath;

            return defer.promise;
        }

        function drawNumberedBlueBox(canvas, route, text) {
            var ctx = canvas.getContext("2d");
            var stopNumberXPosition = canvas.width / 2;
            var stopNumberYPosition = canvas.height / 2 + 5;

            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.fillStyle = route.color;
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            drawLinesAndText(ctx, canvas.width, canvas.height, text, "14px arial", "white", 0.5, "center", stopNumberXPosition, stopNumberYPosition);

            return canvas.toDataURL();
        }

        function getStopIcon(fontColor, route, stop) {
            var canvas = createCanvas(25, 25);
            return drawNumberedBlueBox(canvas, route, stop.stopIconText);
        }

        function createCanvas(width, height) {
            var canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;
            return canvas;
        }

        function drawLinesAndText(ctx, width, height, text, textFont, strokeStyle, lineWidth, textAlign, stopNumberXPosition, stopNumberYPosition) {
            ctx.strokeStyle = "black";
            ctx.strokeRect(0, 0, width, height);
            ctx.font = textFont;
            ctx.fillStyle = "#fff";
            ctx.strokeStyle = strokeStyle;
            ctx.lineWidth = lineWidth;
            ctx.textAlign = textAlign;
            ctx.strokeText(text, stopNumberXPosition, stopNumberYPosition);
            ctx.fillText(text, stopNumberXPosition, stopNumberYPosition);
        }
    }]);
});