define([
    "./remoteServerModule",
    "../arrays/arrays"
], function (remoteServerModule, arrays) {
    "use strict";

    RemoteServersModalController.$inject = [
        "$modalInstance",
        "loadingService",
        "messagesModal",
        "remoteConfigParam",
        "remoteServerService",
        "remoteExceptionHandler",
    ];

    function RemoteServersModalController(
        $modalInstance,
        loadingService,
        messagesModal,
        remoteConfigParam,
        remoteServerService,
        remoteExceptionHandler) {

        this.remoteServicesConfigParams = remoteConfigParam;
        this.defaultServer = 0;
        this.oldDefaultServer = 0;

        this.$modalInstance = $modalInstance;
        this.loadingService = loadingService;
        this.messagesModal = messagesModal;
        this.remoteServerService = remoteServerService;
        this.remoteExceptionHandler = remoteExceptionHandler;
    }

    RemoteServersModalController.prototype.testConnection = function (remoteConfig) {
        var self = this;
        return self.loadingService(self.remoteServerService.testRoutingConnection(remoteConfig))
            .catch(self.remoteExceptionHandler())
            .then(function (messages) {
                return self.messagesModal("dialog.result", messages);
            });
    };

    RemoteServersModalController.prototype.addRemoteServers = function () {
        this.remoteServicesConfigParams.push({
            defaultServer: false,
            name: "",
            sourceId: "",
            remoteServicesBaseUrl: "",
            isOrchestrated: false,
            hasExternalIp: false,
            type: ""
        });
    };

    RemoteServersModalController.prototype.removeRemoteServers = function (index) {
        if (this.isRemoveDisabled(this.remoteServicesConfigParams[index])) {
            return;
        }
        arrays.removeAt(this.remoteServicesConfigParams, index);
    };

    RemoteServersModalController.prototype.isRemoveDisabled = function (remoteConfig) {
        return !!remoteConfig.defaultServer;
    };

    RemoteServersModalController.prototype.updateDefault = function () {
        var remoteServicesConfigParams = this.remoteServicesConfigParams;
        remoteServicesConfigParams[this.defaultServer].defaultServer = true;
        remoteServicesConfigParams[this.oldDefaultServer].defaultServer = false;
        this.oldDefaultServer = this.defaultServer;
    };

    RemoteServersModalController.prototype.ok = function (remoteServersConfig) {
        var self = this;
        self.loadingService(self.remoteServerService.updateRemoteServicesConfigParams(remoteServersConfig))
            .catch(self.remoteExceptionHandler())
            .then(function () {
                return self.$modalInstance.close(remoteServersConfig);
            });
    };

    RemoteServersModalController.prototype.cancel = function () {
        this.$modalInstance.dismiss();
    };

    return remoteServerModule.controller("remoteServerModalController", RemoteServersModalController);
});