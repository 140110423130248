define([
    // ordenar alfabeticamente
    "./address/address",
    "./arrangement/arrangement",
    "./arrays/arrays",
    "./attributable/attributable",
    "./backgroundTaskJob/backgroundTaskJob",
    "./c3/c3",
    "./cancellation/cancellation",
    "./charting/charting",
    "./charting/charting",
    "./cityStateFormat/cityStateFormat",
    "./classifiable/classifiable",
    "./collections/collections",
    "./colorpicker/colorpicker",
    "./colors/colors",
    "./commonScript/commonScript",
    "./dashboards/dashboard",
    "./directives/directives",
    "./entityDTO/entityDTO",
    "./filter/filter",
    "./form/form",
    "./functioningEdition/functioningEdition",
    "./ganttChart/ganttChart",
    "./genericParams/genericParams",
    "./jsonpatches/jsonpatches",
    "./maps/maps",
    "./menu/menu",
    "./menu/menuCommons/menuCommons",
    "./monitor/monitor",
    "./monitoringCommons/monitoringCommons",
    "./nlgAnalysisTree/nlgAnalysisTree",
    "./nlgAudit/nlgAudit",
    "./nlgBasket/nlgBasket",
    "./nlgDualList/nlgDualList",
    "./nlgFilterFieldInput/nlgFilterFieldInput",
    "./nlgHealthChecker/healthChecker",
    "./nlgIntlPhoneNumber/intlPhoneNumber",
    "./nlgLGPD/nlgLGPD",
    "./nlgMicrostrategy/microstrategy",
    "./nlgMultiSelect/nlgMultiSelect",
    "./nlgNavigate/nlgNavigate",
    "./nlgNullableCheckbox/nlgNullableCheckbox",
    "./nlgNumberInput/nlgNumberInput",
    "./nlgOperationOverview/operationOverview",
    "./nlgPageTitle/nlgPageTitle",
    "./nlgPagination/nlgPagination",
    "./nlgPermission/nlgPermission",
    "./nlgPortlet/nlgPortlet",
    "./nlgProgressBar/nlgProgressBar",
    "./nlgSaveAndContinue/nlgSaveAndContinue",
    "./nlgScriptSqlQueryReport/nlgScriptSqlQueryReport",
    "./nlgSelection/nlgSelection",
    "./nlgSingleList/nlgSingleList",
    "./nlgTable/nlgTable",
    "./nlgTableDynamicColumns/nlgTableDynamicColumns",
    "./nlgTableauViz/nlgTableauViz",
    "./nlgTagInput/nlgTagInput",
    "./nlgTemplate/nlgTemplate",
    "./nlgTruckAllocation/truckAllocation",
    "./nodeCommands/nodeCommands",
    "./number/number",
    "./objects/objects",
    "./operation/operation",
    "./optimization/optimization",
    "./panel/panel",
    "./path/path",
    "./position/position",
    "./region/region",
    "./remote/remote",
    "./remoteServer/remoteServer",
    "./revision/revision",
    "./routingConfig/routingConfig",
    "./simulation/simulation",
    "./sorting/sorting",
    "./tdnHelp/tdnHelp",
    "./templateFactory/templateFactory",
    "./three/three",
    "./time/time",
    "./translate/translate",
    "./uiBootstrap/uiBootstrap",
    "./user/user",
    "./violation/violation",
    "./xls/xls",
], function () {
});
