define(["./nlgTruckAllocationModule", "../arrays/arrays", "angular", "text!./nlgTruckAllocation.html", "json!../tdnHelp/tdnSpecification.json"],
    function (nlgTruckAllocationModule, arrays, angular, template, tdnSpecification) {
        "use strict";

        return nlgTruckAllocationModule.directive("nlgTruckAllocation", [function () {
            return {
                restrict: "E",
                template: template,
                scope: {},
                controller: ["$scope",
                    "autocompleteFactory",
                    "messagesModal",
                    "remoteExceptionHandler",
                    "$q",
                    "nlgTruckAllocationService",
                    "nlgTruckAllocation",
                    "$translate",
                    "spreadsheetExportService",
                    "tdnHelp",
                    function ($scope, autocompleteFactory, messagesModal, remoteExceptionHandler, $q, nlgTruckAllocationService, nlgTruckAllocation, $translate, spreadsheetExportService, tdnHelp) {
                        var filterPatch = [], paginationApi;
                        $scope.truckAllocationSelection = [];
                        $scope.tdn = undefined;
                        if (tdnSpecification && tdnSpecification[tdnHelp.getContext()] && tdnSpecification[tdnHelp.getContext()]["truck-allocation"]) {
                            $scope.tdn = tdnSpecification[tdnHelp.getContext()]["truck-allocation"];
                        }
                        $scope.context = tdnHelp.getContext();

                        $scope.model = {
                            isSelectedAllTruckAllocations: false
                        };

                        $scope.trucks = autocompleteFactory.lazyLoader("trucks");

                        $scope.filterServiceUrl = nlgTruckAllocation.createFilter;

                        $scope.tableOptions = {
                            records: [],
                            onRegisterPagination: function (api) {
                                paginationApi = api;
                            },
                            pagination: {
                                onPaginate: function (firstResult, maxResults) {
                                    return nlgTruckAllocationService.getAllocations( firstResult, maxResults, filterPatch )
                                        .then(function (response) {
                                            var result = response.data;
                                            result.result = result.result[0].truckAllocations;

                                            arrays.each(result.result, function (record) {
                                                record.start = new Date(record.start);
                                                record.end = new Date(record.end);

                                                record.previousPinned = record.pinned;
                                                record.originalStart = new Date(record.start);
                                                record.originalEnd = new Date(record.end);
                                                record.previousObservations = record.observations;
                                            });
                                            return result;
                                        });
                                }
                            }
                        };

                        $scope.filterTruckAllocations = function (patch) {
                            filterPatch = patch;
                            resetSelection();
                            return paginationApi.seekPage(1);
                        };

                        $scope.createAllocation = function () {
                            $scope.model.isSelectedAllTruckAllocations = false;
                            return {
                                pinned: true
                            };
                        };

                        $scope.saveAllocations = function (truckAllocation) {
                            truckAllocation.pinned = true;
                            nlgTruckAllocationService.saveAllocations(truckAllocation)
                                .then(function () {
                                    messagesModal("dialog.success", {
                                        keyBundle: "edition.truck.allocation.saveSuccessful",
                                        parameters: [$scope.truckAllocationSelection.length]
                                    })
                                        .then(function () {
                                            resetSelection();
                                            return paginationApi.seekPage(1);
                                        });
                                })
                                .catch(remoteExceptionHandler());
                        };

                        $scope.removeAllocation = function (truckAllocation, index) {
                            if (truckAllocation.truck === null) {
                                return $scope.tableOptions.records.splice(index, 1);
                            }
                            messagesModal.cancellable("dialog.warning", ["edition.truck.allocation.deleteAllocation"])
                                .then(function () {
                                    if ($scope.isTruckAllocationSelected(index)) {
                                        updateIndexes(index);
                                    }
                                    $scope.tableOptions.records.splice(index, 1);
                                    if (!truckAllocation.id) {
                                        return;
                                    }
                                    return nlgTruckAllocationService.removeAllocation(truckAllocation);
                                }).catch(function (errorResponse) {
                                if (angular.isUndefined(errorResponse) || angular.isUndefined(errorResponse.data)) {
                                    return $q.reject(errorResponse);
                                } else {
                                    return remoteExceptionHandler()(errorResponse);
                                }
                            });
                        };

                        function updateIndexes(removed) {
                            var truckAllocationSelectionUpdated = [];
                            arrays.remove($scope.truckAllocationSelection, removed);
                            arrays.each($scope.truckAllocationSelection, function (index) {
                                if (index > removed) {
                                    truckAllocationSelectionUpdated.push(index - 1);
                                } else {
                                    truckAllocationSelectionUpdated.push(index);
                                }
                            });
                            $scope.truckAllocationSelection = truckAllocationSelectionUpdated;
                        }

                        function resetSelection() {
                            arrays.clear($scope.truckAllocationSelection);
                            $scope.model.isSelectedAllTruckAllocations = false;
                        }

                        $scope.toggleAll = function () {
                            if ($scope.model.isSelectedAllTruckAllocations) {
                                var index = -1;
                                arrays.each($scope.tableOptions.records, function () {
                                    index = index + 1;
                                    $scope.truckAllocationSelection.push(index);
                                });
                            } else {
                                resetSelection();
                            }
                        };

                        $scope.toggleSelection = function (truckAllocation, index) {
                            if (arrays.contains($scope.truckAllocationSelection, index)) {
                                arrays.remove($scope.truckAllocationSelection, index);
                                $scope.model.isSelectedAllTruckAllocations = false;
                                return;
                            }
                            $scope.truckAllocationSelection.push(index);
                            if ($scope.truckAllocationSelection.length === $scope.tableOptions.records.length) {
                                $scope.model.isSelectedAllTruckAllocations = true;
                            }
                        };

                        $scope.isTruckAllocationSelected = function (index) {
                            return arrays.contains($scope.truckAllocationSelection, index);
                        };

                        $scope.timeChanged = function (record) {
                            if (!isOriginalTime(record)) {
                                record.pinned = true;
                            } else {
                                record.pinned = record.previousPinned;
                            }
                        };

                        function isOriginalTime(record) {
                            if (record.originalStart && record.originalEnd) {
                                var startDate = new Date(record.start);
                                var endDate = new Date(record.end);
                                return record.originalStart.getTime() === startDate.getTime() && record.originalEnd.getTime() === endDate.getTime();
                            }
                            return true;
                        }

                        $scope.getAllTruckAllocations = function () {
                            return nlgTruckAllocationService.getAllocations(0, null, filterPatch)
                                .then(function (response) {
                                    var result = response.data;
                                    result.result = result.result[0].truckAllocations;

                                    arrays.each(result.result, function (record) {
                                        record.start = new Date(record.start);
                                        record.end = new Date(record.end);
                                    });
                                    return result.result;
                                });
                        };

                        $scope.exportTruckAllocationSpreadsheet = function () {
                            $scope.getAllTruckAllocations()
                                .catch(remoteExceptionHandler())
                                .then(function (data) {
                                    return $scope.getTruckAllocationToSpreadsheetExport(data);
                                })
                                .then(function (dtos) {
                                    var spreadsheetName = $translate.instant("truckAllocation.spreadsheet");
                                    var headers = createHeaders();
                                    return spreadsheetExportService.exportSpreadsheet(spreadsheetName, spreadsheetName, headers, dtos);
                                });
                        };

                        function createHeaders() {
                            return [
                                $translate.instant("presentationnode.nodetype.trip"),
                                $translate.instant("presentationnode.nodeproperty.truck"),
                                $translate.instant("presentationnode.nodeproperty.carrier"),
                                $translate.instant("presentationnode.nodeproperty.vehicle"),
                                $translate.instant("presentationnode.nodeproperty.start.date"),
                                $translate.instant("presentationnode.nodeproperty.end.date"),
                                $translate.instant("presentationnode.nodeproperty.observations")
                            ];
                        }

                        $scope.getTruckAllocationToSpreadsheetExport = function (data) {
                            var truckList = [];
                            angular.forEach(data, function (truckAllocation) {
                                var truckDto = [
                                    truckAllocation.trip !== null ? truckAllocation.trip.code : "",
                                    truckAllocation.truck !== null ? truckAllocation.truck.name + " - " + truckAllocation.truck.sourceId : "",
                                    truckAllocation.carrier !== null ? truckAllocation.carrier.name : "",
                                    truckAllocation.vehicle !== null ? truckAllocation.vehicle.name : "",
                                    truckAllocation.start !== null ? new Date(truckAllocation.start).toLocaleString() : "",
                                    truckAllocation.end !== null ? new Date(truckAllocation.end).toLocaleString() : "",
                                    truckAllocation.observations !== null ? truckAllocation.observations : ""
                                ];
                                truckList.push(truckDto);
                            });
                            return truckList;
                        };
                    }]
            };
        }]);
    });

